import { render, staticRenderFns } from "./Service.vue?vue&type=template&id=17000b48"
import script from "./Service.vue?vue&type=script&setup=true&lang=js"
export * from "./Service.vue?vue&type=script&setup=true&lang=js"
import style0 from "./Service.vue?vue&type=style&index=0&id=17000b48&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports