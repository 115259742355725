<template>
  <div class="relative cursor-pointer w-full">
    <div
      class="absolute pl-3 inset-y-0 flex items-center z-10"
      @click="$emit('onSearch', model)"
    >
      <SearchIcon />
    </div>
    <input
      v-model="model"
      type="text"
      :placeholder="$t('new_units.objects.search')"
      class="rounded-xl focus_outline-none pl-10 w-full text-sm font-medium border border-borderColor search-container"
      @keyup.enter="$emit('onSearch', model)"
    />
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { useI18n } from '@/hooks/useI18n'

import SearchIcon from '../assets/icons/search.vue'

const model = ref('')
const $t = useI18n()
</script>
